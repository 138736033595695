import * as React from "react";
import Icon from "../components/Icon/Icon";
import Layout from "../components/Layout/Layout";
import HeroParagraph from "../_paragraph/HeroParagraph/HeroParagraph";
import ImageAndTextParagraph from "../_paragraph/ImageAndTextParagraph/ImageAndTextParagraph";
import * as styles from "./strategie-hr-change-managment.module.css";
import useWindowSize from "../helpers/useWindowDimension";
const StrategieHrChangeManagment = () => {
  const windowSize = useWindowSize();
  const [isDesktop, setIsDesktop] = React.useState(false);
  React.useEffect(() => {
    if (windowSize.width > 1500) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
    return () => {};
  }, [windowSize.width]);
  return (
    <Layout>
      <section>
        <div className={styles.heroOuter}>
          <div className={styles.heroContainer}>
            <div className={styles.heroHeadline}>
              {isDesktop && (
                <h1>
                  Strategie HR +<br></br> &emsp; Change managment
                </h1>
              )}
              {!isDesktop && (
                <h1>
                  Strategie HR +<br></br> Change managment
                </h1>
              )}
            </div>
          </div>
          <div className={styles.heroImage}>
            <Icon icon={"connectionComputers"} />
          </div>
        </div>
      </section>
      <section>
        <div className={`grid-8--mobile  ${styles.citazione}`}>
          <h2>
            "Tutte le famiglie felici sono uguali, ogni famiglia infelice è
            infelice a modo suo".
          </h2>
          <div className={styles.autore}>
            <p>L.Tolstoj</p>
          </div>
        </div>
      </section>
      <section>
        <div className={styles.outer}>
          <div className={styles.container}>
            {!isDesktop && (
              <div className={styles.iconOuter}>
                <div className={styles.firstIconContainer}>
                  <Icon icon="cakeLeft"></Icon>
                </div>
                <div className={styles.secondIconContainer}>
                  <Icon icon="arrowDownRight"></Icon>
                </div>
              </div>
            )}
            {isDesktop && (
              <div className={styles.desktopIconOuter}>
                <Icon icon="arrowIconDown"></Icon>
              </div>
            )}
          </div>
        </div>
        <div className={styles.outer}>
          <div className={styles.container}>
            <div className={styles.boldParagraph}>
              <p>
                Anche le aziende funzionano così. Ciascuna ha bisogno di una
                comprensione approfondita della sua peculiarità prima che si
                possa scegliere un approccio operativo realmente efficace per le
                sue disfunzionalità.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.imagesOuter}>
          <div className={styles.imagesContainer}>
            <div className={styles.iconImagesOuter}>
              <div className={styles.firstIconImagesContainer}>
                <Icon icon="arrowDownRight"></Icon>
              </div>
              <div className={styles.secondIconImagesContainer}>
                <Icon icon="businessSchema"></Icon>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.outer}>
          <div className={(styles.container, styles.desktopSecondRow)}>
            <div className={styles.paragraphCtaContainer}>
              <strong>
                Noi di P&B proponiamo strategie e piani d’azione risolutivi,
                ispirati alle più recenti best practice internazionali,
              </strong>
              <p>
                solo dopo aver provato a ricostruire - insieme ai referenti
                aziendali di ciascun progetto – il cammino che l’organizzazione
                ha percorso: le ragioni profonde dei suoi successi, le
                contingenze che hanno accompagnato i suoi momenti di impasse, le
                potenzialità di crescita messe in campo di fronte ai cambiamenti
                imprevisti del suo contesto di riferimento.
              </p>
              <a className={styles.scopriDiPiuCTA} href="#contatti">
                Scopri di più
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default StrategieHrChangeManagment;
