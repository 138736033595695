// extracted by mini-css-extract-plugin
export var autore = "strategie-hr-change-managment-module--autore--kcuJt";
export var boldParagraph = "strategie-hr-change-managment-module--boldParagraph--yxXhE";
export var citazione = "strategie-hr-change-managment-module--citazione--8jikZ";
export var container = "strategie-hr-change-managment-module--container--zI5xU";
export var desktopIconOuter = "strategie-hr-change-managment-module--desktopIconOuter--dsjs2";
export var desktopSecondRow = "strategie-hr-change-managment-module--desktopSecondRow--SS666";
export var firstIconContainer = "strategie-hr-change-managment-module--firstIconContainer--o2LAy";
export var firstIconImagesContainer = "strategie-hr-change-managment-module--firstIconImagesContainer--b6D2T";
export var heroContainer = "strategie-hr-change-managment-module--heroContainer--BOXre";
export var heroHeadline = "strategie-hr-change-managment-module--heroHeadline--X1BWC";
export var heroImage = "strategie-hr-change-managment-module--heroImage--2LFsy";
export var heroOuter = "strategie-hr-change-managment-module--heroOuter--AtSI+";
export var iconImagesOuter = "strategie-hr-change-managment-module--iconImagesOuter--s4-XP";
export var iconOuter = "strategie-hr-change-managment-module--iconOuter--PIqBO";
export var imagesContainer = "strategie-hr-change-managment-module--imagesContainer--J7wig";
export var imagesOuter = "strategie-hr-change-managment-module--imagesOuter--BnTvt";
export var outer = "strategie-hr-change-managment-module--outer--jsY3F";
export var paragraphCtaContainer = "strategie-hr-change-managment-module--paragraphCtaContainer--Pk9-2";
export var secondIconContainer = "strategie-hr-change-managment-module--secondIconContainer--9KqVp";
export var secondIconImagesContainer = "strategie-hr-change-managment-module--secondIconImagesContainer--tj0hk";